import React, { useEffect } from 'react'
import {observer} from "mobx-react-lite";
import {unityStore} from "../../store/unityStore";
import {isMobile} from "react-device-detect";
import {cacheService} from "../../services/cacheService";
import apiService from "../../services/apiServices";
import {loadingStore} from "../../store/loadingStore";
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { roomStore } from '../../store/roomStore'
import useErrorHandler from '../../hooks/useErrorHandler'

const Room = observer(() => {
    const {setLoadUnity, setIsActiveMobile} = unityStore;
    const {toggleUnityLoading} = loadingStore;

    const { getRoomData } = roomStore;
    const currentLocation = useLocation();
    const navigate = useNavigate();
    const { roomId } = useParams()
    // const roomImg = currentLocation.state && currentLocation.state.roomImg;
    // const roomName = currentLocation.state && currentLocation.state.roomName;
    const getRoomByID = (id) => apiService.getRoomByID(id);

    useErrorHandler();

    const getRoom = async () => {
        const {data: {data}} = await getRoomByID(roomId);
        cacheService.set("EnvMap", data?.preview_image);
        cacheService.set("RoomName", data?.room_name);
        toggleUnityLoading(false);
        setIsActiveMobile(true);
    };

    useEffect(() => {
        setLoadUnity(true);
        toggleUnityLoading(true);
        const getData = async ()=> {
            try {
                toggleUnityLoading(true);
                await getRoomData(roomId, currentLocation, navigate)
                toggleUnityLoading(false);
            } catch (error) {
                navigate("/dashboard")
                toggleUnityLoading(false);
            }
        }
        getData()
        if (isMobile) {
            void getRoom();
        }
    }, []);

    return (
        <div className="room-container"></div>
    );
});

export default Room;