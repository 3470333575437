import {makeAutoObservable} from "mobx";
import {cacheService} from "../services/cacheService";

class UnityLayoutStore {
    isRegistered = false;
    isSceneLoaded = false;
    isCreator = false;
    isPrivate = false;
    isDraft = false;
    isMyRoom = false;
    unityContextIsReady = false;
    isActiveControllers = false;
    controllersProps = "";
    maxVolume = [];
    currentUsersIds = null;
    isActiveCamera = false;
    isActiveControl = false;
    isSaveAsTemplate = false;
    screenShare = false;
    travelMode = false;
    toggleMap = false;
    showLeaveRoomPopup = false;
    leaveRoom = false;
    showClearContentPopup = false;
    clearContent = false;
    showPortalPopup = false;
    portalTo = false;
    portalData = "";
    openPublishModal = false;
    openUnPublishModal = false;
    openInviteModal = false;
    showLogOutPopup = false;
    sendMessage = null;
    activeEvent = null;
    logOutTo = false;
    removed = 0;
    openWheel = false;
    touch = true;
    privateRoom = null;
    pdfId = "";
    gameUrl = "";
    bannerUrl = "";
    gamblingTableUrl = "";
    isActiveControlQatar = false;
    nextRoom = null;
    prevRoom = null;
    jtxData = [];
    decorationPosition = "";
    isLoadSpin = false;
    changedBySocket = false;

    constructor() {
        makeAutoObservable(this);
    }

    setIsRegistered = bool => this.isRegistered = bool;
    setIsSceneLoaded = bool => this.isSceneLoaded = bool;
    setIsCreator = bool => this.isCreator = bool;
    setIsPrivate = bool => this.isPrivate = bool;
    setIsDraft = bool => this.isDraft = bool;
    setIsMyRoom = bool => this.isMyRoom = bool;
    setUnityContextIsReady = bool => this.unityContextIsReady = bool;
    setIsActiveControllers = bool => this.isActiveControllers = bool;
    setControllersProps = string => this.controllersProps = string;
    setMaxVolume = data => this.maxVolume = data;
    setCurrentUsersIds = data => this.currentUsersIds = data;
    setIsActiveCamera = bool => this.isActiveCamera = bool;
    setIsActiveControl = bool => this.isActiveControl = bool;
    setIsSaveAsTemplate = bool => this.isSaveAsTemplate = bool;
    setScreenShare = bool => this.screenShare = bool;
    setTravelMode = bool => this.travelMode = bool;
    setToggleMap = bool => this.toggleMap = bool;
    setShowLeaveRoomPopup = bool => this.showLeaveRoomPopup = bool;
    setLeaveRoom = bool => this.leaveRoom = bool;
    setShowClearContentPopup = bool => this.showClearContentPopup = bool;
    setClearContent = bool => this.clearContent = bool;
    setShowPortalPopup = bool => this.showPortalPopup = bool;
    setPortalTo = bool => this.portalTo = bool;
    setPortalData = string => this.portalData = string;
    setOpenPublishModal = bool => this.openPublishModal = bool;
    setOpenUnPublishModal = bool => this.openUnPublishModal = bool;
    setOpenInviteModal = bool => this.openInviteModal = bool;
    setShowLogOutPopup = bool => this.showLogOutPopup = bool;
    setSendMessage = func => this.sendMessage = func;
    setSendMessageCallback = arr => this.sendMessage(...arr);
    setActiveEvent = data => this.activeEvent = data;
    setLogOutTo = bool => this.logOutTo = bool;
    setRemoved = number => this.removed = number;
    setOpenWheel = bool => this.openWheel = bool;
    setIsGuest = () => cacheService.set('UserType', 'guest');
    setTouch = bool => this.touch = bool;
    setPrivateRoom = data => this.privateRoom = data;
    setPdfId = string => this.pdfId = string;
    setGameUrl = string => this.gameUrl = string;
    setBannerUrl = string => this.bannerUrl = string;
    setGamblingTableUrl = string => this.gamblingTableUrl = string;
    setIsActiveControlQatar = bool => this.isActiveControlQatar = bool;
    setNextRoom = data => this.nextRoom = data;
    setPrevRoom = data => this.prevRoom = data;
    setJtxData = data => this.jtxData = data;
    onSetDecorationPosition = data => this.decorationPosition = data;
    OnSelectionTransformChange = data => {
        this.controllersProps = {...this.controllersProps, props: data};
    };

    setIsLoadSpin = (bool) => {
        this.isLoadSpin = bool;
    }

    setRoomStatus = (data) => {
        if(+(data.room_id) === +(cacheService.getRoomId)) {
            this.setIsPrivate(data.publish_type === 3);
            this.setIsDraft(data.publish_type === 2);
            this.changedBySocket = true;
        }
    }

    clearUnityLayoutData = () => {
        this.isCreator = false;
        this.isPrivate = false;
        this.isDraft = false;
        this.isMyRoom = false;
        this.isActiveControllers = false;
        this.controllersProps = "";
        this.maxVolume = [];
        this.currentUsersIds = null;
        this.isActiveCamera = false;
        this.isActiveControl = false;
        this.isSaveAsTemplate = false;
        this.screenShare = false;
        this.travelMode = false;
        this.toggleMap = false;
        this.showLeaveRoomPopup = false;
        this.leaveRoom = false;
        this.showClearContentPopup = false;
        this.clearContent = false;
        this.showPortalPopup = false;
        this.portalTo = false;
        this.portalData = "";
        this.openPublishModal = false;
        this.openUnPublishModal = false;
        this.openInviteModal = false;
        this.openAvatarActions = false;
        this.showLogOutPopup = false;
        this.sendMessage = () => {};
        this.activeEvent = null;
        this.logOutTo = false;
        this.removed = 0;
        this.openWheel = false;
        this.isGuest = cacheService.isGuest;
        this.touch = true;
        this.privateRoom = null;
        this.pdfId = "";
        this.gameUrl = "";
        this.bannerUrl = "";
        this.gamblingTableUrl = "";
        this.changedBySocket = false;
    };
}

export const unityLayoutStore = new UnityLayoutStore();
