import axios from "axios";
import {cacheService} from "./cacheService";
import {errorStore} from "../store/errorStore";
import {translationStore} from "../store/translationStore";
import {Languages} from "../constants";

const baseInstant = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

const baseEcommerce =
    process.env.REACT_APP_ECOMMERCE || "https://space-api.pandamr.io/ecommerce/";

export class ApiService {
    constructor(_axios = axios.create(), cacheService) {
        this._axios = _axios;
        this._cacheService = cacheService;
        this.product = "222162273";
    }

    request = (
        path,
        {
            method = "GET",
            data, query, headers,
            cancelToken, onUploadProgress,
            responseType,
        } = {},
    ) => {
        return this._axios.request({
            url: path,
            data,
            method,
            params: query,
            headers,
            cancelToken,
            onUploadProgress,
            responseType,
        }).catch(err => {
            if (!err || !err.response || err?.response?.status >= 500) {
                errorStore.setError({key: "500"});
            } else {
                errorStore.setError({key: err.response.data.errors?.translation_key, ...err.response.data.errors});
            }
            throw err.response.data;
        });
    };

    _generate_header = () => {
        return {
            Authorization: this._cacheService.get("Authorization") || "",
            "Access-Control-Allow-Origin": "*",
            product: Number(this.product),
        };
    };

    _generate_header_for_templates = () => {
        return {
            "Language-Code": translationStore.activeLanguage ? Languages[translationStore.activeLanguage].value : "eng",
        };
    };

    /* AUTH METHODS */

    getCheckEmail = (query) =>
        this.request("users/check_user_with_email/", {
            query,
        });

    setUserLogin = (data) =>
        this.request("users/login/", { method: "POST", data });

    setFastexLoginToken = (query, data) =>
        this.request("users/login_fastex/", {
            query,
            headers: {
                ...this._generate_header_for_templates(),
            },
            method: "POST",
            data,
        });

    setGoogleLoginToken = (data) =>
        this.request("users/login_google/", {
            method: "POST",
            headers: {
                ...this._generate_header_for_templates(),
            },
            data,
        });

    setAppleLoginToken = (data) =>
        this.request("users/login_apple/", {
            method: "POST",
            headers: {
                ...this._generate_header_for_templates(),
            },
            data,
        });

    getCategores = () =>
        this.request("room/categories/", {
            headers: {
                ...this._generate_header(),
            },
        });

    setNewUser = (data) =>
        this.request("users/", {
            method: "POST",
            headers: {
                ...this._generate_header(),
                ...this._generate_header_for_templates(),
            },
            data,
        });

    setUserLogOut = () =>
        this.request("users/logout/?expire_all=True", {
            method: "POST",
            headers: { ...this._generate_header() },
        });

    setUserForgotPassEmail = (data) =>
        this.request("users/forgot_password/", {
            method: "POST",
            headers: {
                ...this._generate_header(),
                ...this._generate_header_for_templates(),
            },
            data,
        });

    setUserCreatePassword = (data) =>
        this.request("users/set_new_password/", {
            method: "POST",
            headers: { ...this._generate_header() },
            data,
        });

    setUserCreatePasswordUcraf = (data) =>
        this.request("users/set_new_password_ucraft/", { method: "POST", data });

    setUserForgotToken = (data) =>
        this.request("users/reset_password/", {
            method: "POST",
            headers: { ...this._generate_header() },
            data,
        });

    setUserNameById = (id, data) =>
        this.request(`users/${id}/`, {
            method: "PATCH",
            headers: { ...this._generate_header() },
            data,
        });

    setUserEmailVerify = () =>
        this.request("users/user_verification/", {
            method: "POST",
            headers: { ...this._generate_header() },
        });

    setUserEmailVerifyCode = (email, otp) =>
        this.request(`users/verify_email_otp/${email}/${otp}/`, {
            method: "POST",
            headers: { ...this._generate_header() },
        });

    /* ROOMS METHODS */

    getAllSpaces = (headers) =>
        this.request("room/space_design_list/", {
            headers: {
                ...this._generate_header(),
                headers,
            },
        });

    getMySpaces = (headers) =>
        this.request("room/user_templates/", {
            headers: {
                ...this._generate_header(),
                headers,
            },
        });

    getPortalList = (query, headers) =>
        this.request("room/get_space_list/", {
            query,
            headers: {
                ...this._generate_header(),
                headers,
            },
        });

    getAllRooms = (query, headers) =>
        this.request("room/", {
            query,
            headers: {
                ...this._generate_header(),
                headers,
            },
        });

    getRoomSearch = (query, headers) =>
        this.request("room/", {
            query,
            headers: {
                ...this._generate_header(),
                headers,
            },
        });

    getRoomByID = (id, query) =>
        this.request(`room/${id}/`, {
            query,
            headers: {
                platform: "WebGL",
                ...this._generate_header(),
            },
        });

    setFastexRoomFromTemplate = (data) =>
        this.request("room/create_fastex_room/", {
            method: "POST",
            headers: {
                ...this._generate_header(),
            },
            data,
        });

    setRoomFromUserTemplate = (data) =>
        this.request("room/create_room_from_template/", {
            method: "POST",
            headers: {
                ...this._generate_header(),
            },
            data,
        });

    setUserGuest = () => this.request(`users/guest_token/`);

    getCurrentUser = () =>
        this.request("users/current_user", {
            headers: {
                ...this._generate_header(),
            },
        });

    setDeleteRoom = (id, headers) =>
        this.request(`room/${id}/`, {
            method: "DELETE",
            headers: {
                ...this._generate_header(),
                headers,
            },
        });

    setRoomName = (id, data, headers) =>
        this.request(`room/${id}/`, {
            method: "PATCH",
            data,
            headers: {
                ...this._generate_header(),
                headers,
            },
        });

    setEcommerceForRoom = (id, ecommerce_domain, ecommerce_id) =>
        this.request(`room/${id}/`, {
            method: "PATCH",
            data: {
                ecommerce_domain,
                ecommerce_id,
            },
            headers: {
                ...this._generate_header(),
            },
        });

    setPublishRoom = (id, publish_to) =>
        this.request(`room/publish_room/${id}/`, {
            method: "PATCH",
            headers: {
                ...this._generate_header(),
            },
            data: { publish_type: publish_to },
        });

    setInviteUser = (room_public_id, users_list) =>
        this.request("room/invite_user/", {
            method: "POST",
            headers: {
                ...this._generate_header(),
                ...this._generate_header_for_templates(),
            },
            data: {
                room_public_id,
                users_list,
            },
        });

    setInviteUserHomeSpace = (users_list) =>
        this.request(`room/invite_home_space/`, {
            method: "POST",
            headers: {
                ...this._generate_header(),
                ...this._generate_header_for_templates(),
            },
            data: {
                users_list,
            },
        });

    getUserHomeSpace = (query) =>
        this.request(`room/get_home_space/`, {
            headers: { ...this._generate_header() },
            query,
        });

    setClearContent = (data, id) =>
        this.request(`room/reset_room/${id}/`, {
            method: "PATCH",
            headers: {
                ...this._generate_header(),
            },
            data,
        });

    setUserAvatar = (data) =>
        this.request("users/update_avatar/", {
            method: "POST",
            headers: {
                ...this._generate_header(),
            },
            data,
        });

    setNewRoom = (data) =>
        this.request("room/", {
            method: "POST",
            headers: {
                ...this._generate_header(),
            },
            data,
        });

    getUserInventory = async (query) =>
        await this.request("room/inventory/", {
            headers: {
                ...this._generate_header(),
            },
            query,
        });

    setEmtyFrame = async (id, data) =>
        await this.request(`room/create_nft_from_empty_frame/${id}/`, {
            method: "PATCH",
            headers: {
                ...this._generate_header(),
            },
            data,
        });

    getCurrencies = async () =>
        await this.request(`room/currencies/`, {
            headers: {
                ...this._generate_header(),
            },
        });

    addPdfModelToScene = (data, roomId, modelType, onUploadProgress) =>
        this.request(`room/create_room_decoration/${roomId}/?model=${modelType}`, {
            method: "POST",
            headers: {
                ...this._generate_header(),
            },
            data,
            onUploadProgress,
        });

    delModel = (id) =>
        this.request(`room/delete_model_from_inventory/${id}/`, {
            method: "DELETE",
            headers: {
                ...this._generate_header(),
            },
        });

    setTemplate = (data) =>
        this.request("room/user_templates/", {
            method: "POST",
            headers: {
                ...this._generate_header(),
            },
            data,
        });

    getRTCToken = (data) =>
        this.request("room/generate_agora_token_action/", {
            method: "POST",
            headers: {
                ...this._generate_header(),
            },
            data,
        });

    setUsersPrivateChannel = (roomId, data) =>
        this.request(`room/invite_private_channel/${roomId}/`, {
            method: "POST",
            headers: {
                ...this._generate_header(),
            },
            data,
        });

    setAnswerPrivateInvite = (roomId, data) =>
        this.request(`room/answer_private_invite/${roomId}/`, {
            method: "POST",
            headers: {
                ...this._generate_header(),
            },
            data,
        });

    removeUserPrivateCall = (roomId, data) =>
        this.request(`room/remove_from_private_call/${roomId}/`, {
            method: "POST",
            headers: {
                ...this._generate_header(),
            },
            data,
        });

    handleLikeRoom = (roomId) =>
        this.request(`users/like-dislike/${roomId}/`, {
            method: "POST",
            headers: {
                ...this._generate_header(),
            },
        });

    getCheckToken = (query) =>
        this.request(`users/check_token/`, {
            query,
            headers: {
                ...this._generate_header(),
            },
        });

    setMuteUnmute = (data) =>
        this.request("/room/mute_unmute/", {
            method: "POST",
            headers: {
                ...this._generate_header(),
            },
            data,
        });

    getTranslation = (language) =>
        this.request(`/landing_page/localize/?lang=${language}`);

    getCountdown = () =>
        this.request(`/users/current_ruffle/`, {
            headers: {
                ...this._generate_header(),
            },
        });

    getDeleteReasons = () =>
        this.request("/users/reasons/", {
            headers: {
                ...this._generate_header(),
            },
        });

    postDeleteReasons = (data) =>
        this.request("/users/delete_profile/", {
            method: "POST",
            headers: {
                ...this._generate_header(),
            },
            data,
        });

    sendOpenRoomEmail = (id) =>
        this.request(`/room/send_email_open_room/${id}/`, {
            headers: {
                ...this._generate_header(),
                ...this._generate_header_for_templates(),
            },
        });

    getBooks = (id, query) =>
        this.request(`/books/book/read_file/?file_id=${id}`, {
            query,
            headers: {
                ...this._generate_header(),
            },
        });

    getUcraftAccesToken = (query) =>
        axios.get(`https://sso.ucraft.ai/api/access-token?${query}`);

    setLogoutUcraft = () =>
        axios.post(
            "https://sso.ucraft.ai/api/logout",
            {},
            { headers: { Authorization: "Bearer " + cacheService.get("ac_token") } }
        );

    getUcraftUser = () =>
        axios.get("https://sso.ucraft.ai/api/user", {
            headers: { Authorization: "Bearer " + cacheService.get("ac_token") },
        });

    getEcommerceParameters = () =>
        axios.get(`${baseEcommerce}website-parameters/`, {
            headers: {
                "http-authorization": this._cacheService.get("ac_token"),
            },
        });

    getProducts = (url, id, first, page, categories) =>
        axios.get(`${baseEcommerce}get-products/`, {
            params: { website_domain: url, website_id: id, first, page, categories },
            headers: {
                "http-authorization": this._cacheService.get("ac_token"),
            },
        });

    getCategorties = (ecommerceDomain, ecommerceProductId) =>
        axios.get(`${baseEcommerce}get-categories/`, {
            params: {
                website_domain: ecommerceDomain,
                website_id: ecommerceProductId,
            },
            headers: {
                "http-authorization": this._cacheService.get("ac_token"),
            },
        });

    setNewWebsite = (id) => axios.post("/ecommerce/create-website/", {
        params: {room_public_id: id},
        headers: {
            "http-authorization": this._cacheService.get("ac_token"),
        }
    });


    getHeadersList = () => this.request("landing_page/header/", {
        headers: {
            ...this._generate_header()
        }
    })

    setUserUcraftToken = (id, data) =>
        this.request(`/users/${id}/`, {
            method: "PATCH",
            headers: {
                ...this._generate_header(),
            },
            data,
        });
    getBookPdfFile = (id) =>
        this.request(`books/book/${id}/`, {
            headers: {
                ...this._generate_header(),
            },
        });

    kickOffUser = (data) =>
        this.request(`room/kick_off_user/`, {
            method: "POST",
            headers: {
                ...this._generate_header(),
            },
            data,
        });

    userBlok = (data) =>
        this.request(`room/block_user/`, {
            method: "POST",
            headers: {
                ...this._generate_header(),
            },
            data,
        });

    userUnBlok = (data) =>
        this.request(`room/block_user/`, {
            method: "PATCH",
            headers: {
                ...this._generate_header(),
            },
            data,
        });

    getDepartmentTypes = () =>
        this.request(`users/user_type/`, {
            method: "GET",
            headers: {
                ...this._generate_header(),
            },
        });

    setHoory = (id, data, headers) =>
        this.request(`room/${id}/`, {
            method: "PATCH",
            data,
            headers: {
                ...this._generate_header(),
                headers,
            },
        });

    setDreamPackage = (data) =>
        this.request("/users/dream_package_auth/", { method: "POST", data });

    ftNftConnect = (data) =>
        this.request(`/ftnft/connect/`, {
            method: "POST",
            data,
            headers: {
                ...this._generate_header(),
            },
        });

    getNftToken = (data) =>
        this.request(`/ftnft/verify/`, {
            method: "POST",
            data,
            headers: {
                ...this._generate_header(),
            },
        });

    buyNft = (data, nftId) =>
        this.request(`/ftnft/buy_nft/${nftId}/`, {
            method: "POST",
            data,
            headers: {
                ...this._generate_header(),
            },
        });

    removeTransaction = (token) =>
        axios.delete(`https://api.ftnft.am/api/v1/checkout/cart/empty`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

    getNftList = (data, query) =>
        this.request(`/ftnft/nft-list/?owned_by_creator_id=${query}`, {
            method: "POST",
            data,
            headers: {
                ...this._generate_header(),
            },
        });

    getNftInfo = (id) => axios.get(`https://api.ftnft.am/api/v1/nfts/${id}`);
    getNftHistory = (id, page = 1) =>
        axios.get(
            `https://api.ftnft.am/api/v1/transactions?nft_id=${id}&page=${page}`
        );

    onLoginMarketplace = (data) =>
        this.request(`/ftnft/login/`, {
            method: "POST",
            data,
            headers: {
                ...this._generate_header(),
            },
        });

    adminUserRole = (id, action, data) =>
        this.request(
            `users/add_or_remove_user_admin_role/${id}/?command=${action}`,
            {
                method: "POST",
                data,
                headers: {
                    ...this._generate_header(),
                },
            }
        );

    getGiftList = () =>
        this.request("gifts/my_gifts/", {
            headers: {
                ...this._generate_header(),
            },
        });

    setUploadTicket = (data) => this.request("room/fast_ticket_upload_ticket/", {
        method: "POST",
        headers: {
            ...this._generate_header()
        },
        data
    });

    setSpaceAnalitics = (id, data) => this.request(`room/space_analitics/${id}/`, {
        method: "POST",
        headers: {
            ...this._generate_header()
        },
        responseType: "arraybuffer",
        data
    });

    successBuyTransaction = (data) => this.request("users/transaction/", {
        method: "POST",
        headers: {
            ...this._generate_header()
        },
        data
    });

    getAvaturnSession = () => this.request("users/avaturn_create_sesion/", {
        method: "POST",
        headers: {
            ...this._generate_header()
        },
    });
}

const apiService = new ApiService(baseInstant, cacheService);

export default apiService;
