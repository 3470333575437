import {ReactComponent as Ru} from '../assets/icons/languages/rectangle/RU.svg';
import {ReactComponent as Us} from '../assets/icons/languages/rectangle/US.svg';
import {ReactComponent as Fr} from '../assets/icons/languages/rectangle/FR.svg';
import {ReactComponent as Ua} from '../assets/icons/languages/rectangle/UA.svg';
import {ReactComponent as De} from '../assets/icons/languages/rectangle/DE.svg';
import {ReactComponent as Ko} from '../assets/icons/languages/rectangle/KO.svg';
import {ReactComponent as Cn} from '../assets/icons/languages/rectangle/CN.svg';
import {ReactComponent as Tr} from '../assets/icons/languages/rectangle/TR.svg';
import {ReactComponent as RuCircle} from '../assets/icons/languages/circle/RU.svg';
import {ReactComponent as UsCircle} from '../assets/icons/languages/circle/US.svg';
import {ReactComponent as FrCircle} from '../assets/icons/languages/circle/FR.svg';
import {ReactComponent as UaCircle} from '../assets/icons/languages/circle/UA.svg';
import {ReactComponent as DeCircle} from '../assets/icons/languages/circle/DE.svg';
import {ReactComponent as KoCircle} from '../assets/icons/languages/circle/KO.svg';
import {ReactComponent as CnCircle} from '../assets/icons/languages/circle/CN.svg';
import {ReactComponent as TrCircle} from '../assets/icons/languages/circle/TR.svg';

export const Languages = {
    RUS: {title: "RUS", icon: <Ru />, value: "ru", circleIcon: <RuCircle />},
    ENG: {title: "ENG", icon: <Us />, value: "eng", circleIcon: <UsCircle />},
    FR: {title: "FR", icon: <Fr />, value: "fr", circleIcon: <FrCircle />},
    UA: {title: "UA", icon: <Ua />, value: "uk", circleIcon: <UaCircle />},
    DE: {title: "DE", icon: <De />, value: "de", circleIcon: <DeCircle />},
    KO: {title: "KO", icon: <Ko />, value: "ko", circleIcon: <KoCircle />},
    CN: {title: "CN", icon: <Cn />, value: "zh-Hans", circleIcon: <CnCircle />},
    TR: {title: "TR", icon: <Tr />, value: "tr", circleIcon: <TrCircle />},
}