import {useLocation} from "react-router-dom";
import React, {useEffect, useRef} from "react";
import gsap from "gsap";
import "./index.scss";
import {isDesktop, isTablet} from "react-device-detect";
import isFirefox from "../../helpers/isFirefox";

const TransitionRouter = ({children}) => {
    const location = useLocation();
    const containerRef = useRef(null);

    useEffect(() => {
        const timeline = gsap.timeline();
        timeline.from(containerRef.current, {
            duration: 0.3,
            opacity: 0,
            x: "100vw",
            ease: "power2.out",
        });
        timeline.to(containerRef.current, {
            duration: 0.3,
            opacity: 1,
            x: "0",
            ease: "power2.out",
        });

        return () => {
            timeline.kill();
        };
    }, [location]);

    return (
        <div
            className={`layer-content ${!isDesktop ? "mobile" : ""} ${isTablet ? "tablet" : ""} ${isFirefox() ? "firefox" : ""}`}
            ref={containerRef}>
            {children}
        </div>
    );
};

export default TransitionRouter;