import React, {lazy, Suspense, useEffect} from "react";
import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import routes from "./routes";
import {unityStore} from "./store/unityStore";
import {observer} from "mobx-react-lite";
import DoubleLogin from "./components/Popups/DoubleLogin";
import {authStore} from "./store/authStore";
import {isMobile} from "react-device-detect";
import InviteShowPopup from "./components/Popups/InviteShowPopup";
import {translationStore} from "./store/translationStore";
import ErrorModal from "./components/ModalError";
import TransitionRouter from "./components/TransitionRouter";
import LoaderAir from "./components/LoaderAir";
import ErrorContainer from "./containers/ErrorContainer";
import RotateDevice from "./components/RotateDevice";
import {loadingStore} from "./store/loadingStore";
import HeaderElementIframe from "./components/Popups/HeaderElement";
import {betStore} from "./store/betStore";
import Mobile from "./containers/mobile";
import useScreenOrientation from "./hooks/useScreenOrientation";
import {dashboardStore} from "./store/dashboardStore";
import {Languages} from "./constants";
import {cacheService} from "./services/cacheService";
import ParticipantsPopup from "./components/Popups/ParticipantsPopup";

const UnityLayout = lazy(() => import("./containers/unityLayout"));

const App = observer(() => {
    const {loadUnity, isActiveMobile} = unityStore;
    const {doubleLogin, inviteMessage} = authStore;
    const {iframeUrl} = betStore;
    const {getTranslationData, activeLanguage} = translationStore;
    const {showHoory} = dashboardStore;
    const orientation = useScreenOrientation();

    const {translateLoading, unityLoading, cubeMapLoading} = loadingStore;

    useEffect(async () => {
        await getTranslationData();
        if(showHoory) {
            const lang = Languages[cacheService.get("Language")]?.value === "eng" || !Languages[cacheService.get("Language")]?.value ? "en" : Languages[cacheService.get("Language")]?.value
            window.$hoory.setLocale(lang);
        }
    }, [activeLanguage]);

    return (
        <Suspense fallback={null}>
            <BrowserRouter>
                <TransitionRouter>
                    <ErrorContainer>
                        <Suspense fallback={<LoaderAir/>}>
                            <Routes>
                                {routes.map((item, index) => (
                                    <Route
                                        key={index}
                                        path={item.path}
                                        element={<item.component/>}
                                        title={item.title}
                                        exact
                                    />
                                ))}
                                <Route path="*" element={<Navigate to="/404" replace/>}/>
                            </Routes>
                        </Suspense>
                    </ErrorContainer>
                </TransitionRouter>
                <Suspense fallback={null}>
                    {loadUnity && !isMobile && <UnityLayout/>}
                </Suspense>
                <LoaderAir backLayer={!(translateLoading || unityLoading || cubeMapLoading)}/>
                <Suspense fallback={null}>
                    {isMobile && !!orientation && <RotateDevice/>}
                    {isMobile && isActiveMobile && <Mobile/>}
                    {doubleLogin && <DoubleLogin/>}
                    {!!inviteMessage && <InviteShowPopup/>}
                </Suspense>
                {iframeUrl ? <HeaderElementIframe/> : <></>}
                <ParticipantsPopup/>
            </BrowserRouter>
            <ErrorModal/>
        </Suspense>
    );
});

export default App;

