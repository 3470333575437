import {action, makeAutoObservable, observable} from "mobx";

class BetStore {
    headers = [];
    iframeUrl = "";
    showHeaderNavbar = false;
    constructor() {
        makeAutoObservable(this, {
            headers: observable,
            iframeUrl: observable,
            showHeaderNavbar: observable,
            setHeaderList: action.bound,
            setIframeUrl: action.bound,
            toggleHeaderNavbar: action.bound,
        });
    }

    setHeaderList = data => {
        this.headers = data;
    }

    setIframeUrl = str => {
        this.iframeUrl = str;
    }

    toggleHeaderNavbar = bool => {
        this.showHeaderNavbar = bool;
    }
}

export const betStore = new BetStore();
