import React, {useRef} from "react";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {keyboardLockStore} from "../../store/keyboardLockStore";
import {betStore} from "../../store/betStore";

const HeaderElementIframe = () => {
    const ref = useRef();
    const {setIframeUrl, iframeUrl} = betStore;
    const {setKeyboardLocked} = keyboardLockStore;

    const closePopup = () => {
        setKeyboardLocked(true)
        setIframeUrl("");
    }
    useOnClickOutside(ref, closePopup);

    return (
        <div className="games-container" ref={ref}>
            <i className="icon-close" onClick={closePopup}/>
            <iframe className="games-iframe" src={iframeUrl} />
        </div>
    )
}

export default HeaderElementIframe;
