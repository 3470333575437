import {useState, useEffect} from 'react'

const useScreenOrientation = () => {
    const [orientation, setOrientation] = useState(window.orientation);

    const updateOrientation = () => {
        setOrientation(window.orientation);
    }

    useEffect(() => {
        window.addEventListener('orientationchange', updateOrientation);
        return () => {
            window.removeEventListener('orientationchange', updateOrientation);
        }
    }, [])

    return orientation;
}

export default useScreenOrientation;