import { makeObservable, observable, action, computed } from 'mobx';
import apiService from '../services/apiServices';
import { cacheService } from '../services/cacheService';
import { errorStore } from './errorStore';

class ParticipantsStore {
  user = null;
  userJoinRoom = false;
  roomCreatorId = null;
  kickOffModal = false;
  blockUserModal = false;
  blockedUsersList = [];
  adminUsers = [];
  userIsBlocked = false;
  makeAdmin = false;

  constructor() {

    makeObservable(this, {
      user: observable,
      roomCreatorId: observable,
      kickOffModal: observable,
      blockUserModal: observable,
      blockedUsersList: observable,
      adminUsers: observable,
      userIsBlocked: observable,
      makeAdmin: observable,
      userJoinRoom: observable,
      isAdminRole: computed,
      isModerator: action.bound,
      handleKickOff: action.bound,
      handleBlockUser: action.bound,
      handleUnBlockUser: action.bound,
      kickOffModalToggle: action.bound,
      blockUserModalToggle: action.bound,
      setBlockedUsers: action.bound,
      setBlockedUser: action.bound,
      setAdminUsers: action.bound,
      setMakeAdmin: action.bound,
      makeAdminHandler: action.bound,
      removeAdminRole: action.bound,
      userIsAdmin: action.bound,
      userRoleIsCreator: action.bound,
      setRoomCreatorId: action.bound,
      setKickOffUser: action.bound,
      setUserJoinRoom: action.bound,
    });

  }

  setUserJoinRoom(bool){
    this.userJoinRoom = bool
  }

  kickOffModalToggle(user) {
    if(user){
      this.user = user
      this.kickOffModal = true;
    }else{
      this.user = null
      this.kickOffModal = false;
    }
  }

  blockUserModalToggle(user) {
    if(user){
      this.user = user
      this.blockUserModal = true;
    }else{
      this.user = null
      this.blockUserModal = false;
    }
  }

  setBlockedUsers(arr){
    this.blockedUsersList = arr
  }

  setAdminUsers(arr){
    this.adminUsers = arr
  }

  setBlockedUser(bool){
    this.userIsBlocked = bool
  }

  async handleKickOff(duration){
    const roomId = Number(cacheService.get('RoomId'))
    try {
      await apiService.kickOffUser({users_public_id:[this.user.publicId], room_public_id:roomId, duration});
      this.kickOffModal = false;
    } catch (e) {
      console.warn('ERROR: ', e.message);
    }
  }

  async handleBlockUser(){
    const roomId = Number(cacheService.get('RoomId'))
    try {
      await apiService.userBlok( {users_public_id: [this.user?.publicId], room_public_id:roomId} );
      this.blockedUsersList = [...this.blockedUsersList, {public_id: this.user?.publicId, username: this.user.name}]
      this.blockUserModal = false;
    } catch (e) {
      console.warn('ERROR: ', e.message);
    }
  }

  async handleUnBlockUser(uid){
    const roomId = Number(cacheService.get('RoomId'))
    try {
      await apiService.userUnBlok( {users_public_id: [uid], room_public_id: roomId} );
      this.blockedUsersList = this.blockedUsersList.filter(user => user.public_id !== uid);
    } catch (e) {
      console.warn('ERROR: ', e.message);
    }
  }

  setMakeAdmin(user){
    if(user){
      this.user = user
      this.makeAdmin = true;
    }else{
      this.user = null
      this.makeAdmin = false;
    }
  }

  async makeAdminHandler(){
    try {
      const roomId = Number(cacheService.get('RoomId'))
      await apiService.adminUserRole(roomId, "add", {users_list: [this.user.publicId]})
      if(!this.userIsAdmin(this.user.publicId)){
        this.adminUsers = [...this.adminUsers, {public_id: this.user?.publicId, username: this.user.name}]
      }
      this.makeAdmin = false;
    } catch (e) {
      console.warn('ERROR: ', e.message);
    }
  }

  async removeAdminRole(id){
    try {
      const roomId = Number(cacheService.get('RoomId'))
      await apiService.adminUserRole(roomId, "remove", {users_list: [id]})
      this.adminUsers = this.adminUsers.filter(user => user.public_id !== id)
      this.makeAdmin = false;
    } catch (e) {
      console.warn('ERROR: ', e.message);
    }
  }

  userIsAdmin(id){
    return !!this.adminUsers.filter(user => user.public_id === id).length
  }

  get isAdminRole(){
    const id = cacheService.getMyId
    return !!this.adminUsers.filter(user => user.public_id === id).length
  }

  setRoomCreatorId(id){
    this.roomCreatorId = id;
  }

  userRoleIsCreator(id){
    return this.roomCreatorId === id
  }

  isModerator(){
    const myId = cacheService.getMyId
    const result = this.userRoleIsCreator(myId) || this.isAdminRole
    return result
  }

  setKickOffUser(userId, roomId, msg){
    if(userId === cacheService.getMyId){
      const message = msg.split("kicked for")[1].trim().split(" ");
      const time = message[1].toLowerCase();
      const value = message[0];
      const options = {
        [time]:value
      }
      errorStore.setError({key:'admin_has_kick_off', ...options})
    }
  }
}

export const participantsStore = new ParticipantsStore();




