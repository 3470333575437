import { makeObservable, observable, action } from 'mobx';

class KeyboardLockStore {
  keyboardLocked = false;

  constructor() {

    makeObservable(this, {
      keyboardLocked: observable,
      setKeyboardLocked: action.bound,
    });

  }

  setKeyboardLocked(bool) {
    this.keyboardLocked = bool;
  }
}

export const keyboardLockStore = new KeyboardLockStore();


