import React, {useMemo, useState} from "react";
import {isMobile} from "react-device-detect";
import {Link, useLocation} from "react-router-dom";
import logo from "../../assets/images/bet-logo-black.svg";
import {cacheService} from "../../services/cacheService";
import {copyTextToClipboard} from "../../utils";
import {observer} from "mobx-react-lite";
import {unityStore} from "../../store/unityStore";
import {translationStore} from "../../store/translationStore";
import { roomStore } from "../../store/roomStore";
import "./style.scss";

const Mobile = observer(() => {
    const [copied, setCopied] = useState(false);
    const {isActiveMobile, setIsActiveMobile} = unityStore;
    const {roomName, roomBackground} = roomStore;
    const {translationData, activeLanguage} = translationStore;
    const currentLocation = useLocation();
    const authToken = cacheService.getAuthToken();
    const roomId = cacheService.get("RoomId");
    const [url, setUrl] = useState("")

    useMemo(() => {
        if (currentLocation.pathname.includes("/dashboard/") && isMobile) {
            setIsActiveMobile(true);
        }
    }, []);

    const handleCopyLink = () => {
        setCopied(true);

        const url = `${window.location.origin}/dashboard/${cacheService.get("RoomId")}`;
        void copyTextToClipboard(url);

        setTimeout(() => {
            setCopied(false);
        }, 800);
    };

    useMemo(() => {
        const defaultUlr = "https://pandamr.page.link/?link=";
        const encodedUrl = encodeURIComponent(`https://pandamr.io/dashboard?command=LoginAndOpenRoom&roomId=${roomId}&userToken=${authToken}`);

        setUrl(String(defaultUlr + encodedUrl));
    }, [authToken, roomId])

    return (
        <div className={`event-in-mobile-container`} style={{
            visibility:
                isActiveMobile ? "visible" : "hidden",
        }}>
            <img alt={"Img"} src={roomBackground} className="background"/>
            <div className="event-title-container">
                <div className="event-title-left-container">
                    <Link className="back" to={`/dashboard`} onClick={() => setIsActiveMobile(false)}>
                        <i className="icon-back"/>
                    </Link>
                    <p className="title">{roomName}<span>
                    {activeLanguage !== 'ARM' ? translationData?.["by"] : ''} {cacheService.get("UserName")} {activeLanguage === 'ARM' ? translationData?.["by"] : ''}
                </span></p>
                </div>
                <button
                    className="p-mr-event-popover-action tooltip"
                    onClick={handleCopyLink}
                >
                    <span className={`tooltiptext ${copied ? "active" : ""}`}>{translationData?.["copied"]}</span>
                    <i className="icon-link"/>
                </button>
            </div>
            <div className="open-on-mobile">
                <img alt={"Logo"} src={logo} className="logo"/>
                <p>{translationData?.["open_application_to"]}</p>
                <>
                    <a className="open-in-app"
                       href={url}>
                        {translationData?.["open_in_app"]}
                    </a>
                </>
            </div>
        </div>
    );
});

export default Mobile;
