import React from "react";
import Logo from "../../assets/loaders/Spin.svg";
import "./style.scss";

const LoaderAir = ({backLayer = false}) => (
    <div className={`loader-content ${backLayer ? "backLayer" : ""}`}>
        <img className="bet-logo-loader" src={Logo} alt=""/>
    </div>
);

export default LoaderAir;
